@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  font-family: "Montserrat", sans-serif;
}

:root {
  --orange-50: #FFF3E0;
  --primary-100: #FFE0B2;
  --primary-200: #FFCC80;
  --primary-300: #FFB74D;
  --primary-400: #FFA726;
  --primary-500: #FF9800;
  --primary-600: #FB8C00;
  --primary-700: #F57C00;
  --primary-800: #EF6C00;
  --primary-900: #E65100;

  --pink-50: #FCE4EC;
  --accent-100: #F8BBD0;
  --accent-200: #F48FB1;
  --accent-300: #F06292;
  --accent-400: #EC407A;
  --accent-500: #E91E63;
  --accent-600: #D81B60;
  --accent-700: #C2185B;
  --accent-800: #AD1457;
  --accent-900: #880E4F;

  --blue-gray-50: #ECEFF1;
  --neutral-100: #CFD8DC;
  --neutral-200: #B0BEC5;
  --neutral-300: #90A4AE;
  --neutral-400: #78909C;
  --neutral-500: #607D8B;
  --neutral-600: #546E7A;
  --neutral-700: #455A64;
  --neutral-800: #37474F;
  --neutral-900: #263238;
}

/* Global Styles */
body {
  @apply bg-neutral-100 text-neutral-900 transition-colors duration-500;
}

/* Dark Mode Styles */
body.dark {
  --orange-50: #FFF3E0;
  --primary-100: #FFE0B2;
  --primary-200: #FFCC80;
  --primary-300: #FFB74D;
  --primary-400: #FFA726;
  --primary-500: #FF9800;
  --primary-600: #FB8C00;
  --primary-700: #F57C00;
  --primary-800: #EF6C00;
  --primary-900: #E65100;

  --pink-50: #FCE4EC;
  --accent-100: #F8BBD0;
  --accent-200: #F48FB1;
  --accent-300: #F06292;
  --accent-400: #EC407A;
  --accent-500: #E91E63;
  --accent-600: #D81B60;
  --accent-700: #C2185B;
  --accent-800: #AD1457;
  --accent-900: #880E4F;

  --blue-gray-50: #ECEFF1;
  --neutral-100: #CFD8DC;
  --neutral-200: #B0BEC5;
  --neutral-300: #90A4AE;
  --neutral-400: #78909C;
  --neutral-500: #607D8B;
  --neutral-600: #546E7A;
  --neutral-700: #455A64;
  --neutral-800: #37474F;
  --neutral-900: #263238;

  @apply bg-neutral-900 text-neutral-100 transition-colors duration-500;
}

/* Card Styles */
.legal-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  border-radius: 0.5rem;
  text-decoration: none;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.legal-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.legal-card:active {
  transform: scale(0.98);
}

/* Typing Effect */
.typing-container {
  justify-content: center;
  align-items: center;
  width: 95%;
  max-width: 100%;
}

.typing-text {
  font-size: 1.2rem;
  font-weight: bold;
}

/* Slide-in Animation */
@keyframes slide-in {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.animate-slide-in {
  animation: slide-in 0.5s ease-out;
}

/* Popup container */
.popup-container {
  position: fixed;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  overflow: auto; /* Allows scrolling if content overflows */
}

/* Popup content */
.popup-content {
  background: white;
  color: black;
  padding: 1.5rem;
  border-radius: 0.5rem;
  max-width: 90vw; /* Responsive max width */
  max-height: 90vh; /* Responsive max height */
  overflow-y: auto; /* Allows scrolling if content overflows */
  position: relative;
}

/* Close icon */
.close-icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: gray;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-icon:hover {
  color: black;
}

/* Robots */
.robot {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.robot-icon {
  font-size: 16px;
  cursor: pointer;
  color: #ffffff;
  background-color: var(--primary-900);
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: background-color 0.3s;
}

/* components/SocialShare.css */

.social-share-container {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
}

.share-icon {
  font-size: 16px;
  cursor: pointer;
  color: #ffffff;
  background-color: var(--primary-900);
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: background-color 0.3s;
}

.share-icon:hover {
  font-size: 26px;
  cursor: pointer;
  color: #ffffff;
  background-color: var(--primary-900);
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: background-color 0.3s;
}

.share-options {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 60px;
  right: 0;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.share-option {
  font-size: 24px;
  color: #000;
  margin: 5px 0;
  text-align: center;
  transition: color 0.3s;
}

.share-option:hover {
  color: var(--primary-900);
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.marquee-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 5px; /* Adjust padding as needed */
}

.marquee-content {
  display: inline-block;
  white-space: nowrap;
  padding-left: 100%; /* Initial space before starting */
  animation: marquee 25s linear infinite; /* Slower speed */
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.icon {
  margin-right: 5px; /* Space between icon and text */
  font-size: 1.2em; /* Adjust icon size */
}

/* Example of a network speed animation */
@keyframes network-speed {
  0% {
    color: #007bff; /* Blue start */
  }
  50% {
    color: #28a745; /* Green midpoint */
  }
  100% {
    color: #007bff; /* Blue end */
  }
}

.marquee-content .icon:last-child {
  animation: network-speed 1s linear infinite; /* Animate network speed icon */
}
